// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//---------------------------issue{No.563} start-----------------------------
// 週間計画表などの_2系コンポーネントの範囲選択表示用クラス（bg-info）のスタイル
:root {
    .bg-info {
        --bs-bg-opacity: .4
    }
}
//---------------------------issue{No.563} end-------------------------------
